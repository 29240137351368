const en = {
  lorem_ipsum:
    'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet, consectetur adipiscing elit. A, ut ipsum lectus purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, ut ipsum lectus purus. ',
  bigcommerce_hubspot_integration: 'BigCommerce HubSpot Integration',
  getting_started_with_bigcommerce_hubspot_integration:
    'Getting started with BigCommerce HubSpot Integration',
  with_bigcomm_hubspot_integration_you_can_sync_bigcomm_contact_customer_with_hubspot_crm:
    'With this BigCommerce HubSpot integration, you can automatically sync all your BigCommerce contacts and customers with HubSpot crm and marketing platform.',
  once_set_up_integration_you_will_be_able_to:
    'Once you set up this integration, you will be able to',
  see_every_action_contact_including_page_view_past_orders_abondoned_card_and_more:
    'See every action each contact has taken including their page views, past orders, abandoned cards and more - in HubSpot crm tidy timeline view',
  segment_contact_customers_list_based_previous_interactions_with_store:
    'Segment contacts and customers into lists based on their previous interactions with your store',
  easily_create_and_send_beautiful_responsive_emails_to_drive_sales:
    'Easily create and send beautiful, responsive emails to drive sales',
  measure_store_performance_with_customer_reports_and__dashboards:
    'Measure your store’s performance with custom reports and dashboards',
  to_get_started_connect_hubspot_account_dont_have_account_create_one_then_return_to_this_window_to_connect:
    'To get started, connect your HubSpot account. If you don’t have a HubSpot account, create one then return to this window to connect it.',
  connect_your_account: 'Connect your account',
  create_free_hubspot_account: 'Create a free hubspot account',
  redirecting_to_hubspot_account_page: 'Redirecting to HubSpot account page',
  sync_bigcommerce_data_with_hubspot: 'Sync BigCommerce data with HubSpot',
  almost_done_last_step_to_sync_existing_bigcommerce_data_to_hubspot:
    'You’re almost done! The last step is to sync your existing BigCommerce data to HubSpot. This will sync all your Contacts, Deals, and Products data to HubSpot.',
  once_sync_your_data_you_ll_see_you_bigcommerce_information_on_hubspot:
    'Once you sync your data, you’ll see all your BigCommerce information on HubSpot so you can start engaging with them right away.',
  skip_data_sync: 'Skip data sync',
  sync_now: 'Sync now',
  gat_tailored_onboarding_emails_straight_to_your_inbox:
    'Get tailored onboarding emails straight to your inbox',
  help_us_make_your_experience_even_better_by_telling_us:
    'Help us make your experience even better by telling us',
  skip_now: 'Skip now',
  which_of_these_sounds_most_like_hubspot_ability:
    'Which of these sounds most like your HubSpot ability?',
  which_of_these_sounds_most_like_bigcommerce_ability:
    'Which of these sounds most like your BigCommerce ability?',
  which_hubspot_plan_you_are_using: 'Which HubSpot plan you are using ?',
  select_options: 'Select Options',
  email: 'Email',
  phone_number: 'Phone number',
  phone: 'Phone',
  complete_onboarding: 'Complete Onboarding',
  congrats_successfully_setup_bigcommerce_hubspot_integration:
    'Congrats! You’ve successfully set up the BigCommerce HubSpot Integration',
  whats_next_go_to_dashboard_learn_more_about_integration:
    "What's next? Go to your dashboard to learn more about the integration.",
  view_dashboard: 'View Dashboard',
  dashboard: 'Dashboard',
  product_information: 'Product Information',
  order_information: 'Order Information',
  customer_information: 'Customer Information',
  bigcommerce: 'BigCommerce',
  hubspot: 'HubSpot',
  last_synced: 'Last Synced',
  settings: 'Settings',
  setup_group_properties: 'Set up groups & properties in HubSpot',
  woocommer_setup_hubspot:
    'In order to view your BigCommerce data correctly in HubSpot, you need to set up groups and properties in your HubSpot account.',
  go_to_sync_now: 'Go to Sync Now',
  products: 'Products',
  start_date: 'Start Date',
  end_date: 'End Date',
  sync_selected_data_row: 'Sync Selected Data Row',
  import_export: 'Import/Export',
  import_data: 'Import Data',
  export_data: 'Export Data',
  summary: 'Summary',
  automatic_syncing: 'Automatic Syncing',
  customer_sync: 'Customer Sync',
  product_sync: 'Product Sync',
  order_sync: 'Order Sync',
  line_item_sync: 'Line Item Sync',
  abandoned_cart_sync: 'Abandoned Cart Sync',
  sync_enabled: 'Sync Enabled',
  sync_disabled: 'Sync Disabled',
  here_to_manage_configurations: 'here to manage configurations',
  click: 'Click',
  cedcommerce_support: 'CedCommerce Support',
  our_other_products: 'Our Other Products',
  hubspot_onboarding_services: 'Hubspot Onboarding Services',
  hubspot_plugins_products: 'Hubspot Plugins & Products',
  custom_development_services: 'Custom Development Services',
  our_plugins: 'Our Plugins',
  hubspot_recommended_products_addon: 'HubSpot Recommended Products Addon',
  our_apps: 'Our Apps',
  import_line_1:
    '* The actions can be used to transfer all of your BigCommerce data to your HubSpot Account. This action will sync details of Products, Customers, Orders and Line Items.',
  import_line_2:
    '* To upload details from BigCommerce to HubSpot, kindly import your data from BigCommerce to App by clicking on ‘Import Data’.',
  import_line_3:
    '* Once the process of data imports get completed, you can upload data on HubSpot by clicking on ‘Upload Data’. It would be better to upload data in smaller chunks using date range filter.',
  import_line_4:
    '* If you have any concerns related to data syncing, kindly contact to MakeWebBetter Support.',
  from_bigcommerce_to_app: 'From BigCommerce to App',
  select_data_type: 'Select Data Type',
  select: 'Select',
  set_date_range: 'Set Date Range',
  from_app_to_hubspot: 'From App to HubSpot',
  setup_line_1:
    'In order to view your BigCommerce data correctly in HubSpot, you need to set up groups and properties in your HubSpot account.',
  setup_line_2:
    'Once you set up groups and properties, you can easily see the following information about your contacts and customers:',
  setup_line_3:
    'Group and property creation is in progress. This should only take a few moments. Thanks for your patience!',
  setup_line_4:
    'Set up lists to segment your contacts and customers based on their previous actions and behaviors.',
  previous_purchase: 'Previous purchases',
  abandoned_cart_details: 'Abandoned cart details',
  and_more: 'And more',
  leads_contact: ' Leads: contacts that have not yet made any orders',
  customer_contact: ' Customers: contacts that have made at least one order',
  abandoned_cart_contacts:
    ' Abandoned Cart: contacts that have added products to their carts, but have not purchased',
  create_lists_in_hubspot: 'Create lists in HubSpot',
  create_workflows_bigcommerce: 'Create Workflows in BigCommerce',
  map_deals_stages_with_hubspot_pipline:
    'Map Deal Stages with HubSpot pipeline',
  create_workflows: 'Create Workflows',
  create_list: 'Create Lists',
  map_deals_line_1:
    'Sync order statuses with deal stages so you can manage your eCommerce pipeline in HubSpot',
  map_deals_line_2:
    'Once you set up groups and properties, you can easily see the following information about your contacts and customers:',
  pending_payment: 'Pending Payment',
  save: 'Save',
  reset_to_default_mapping: 'Reset to Default Mapping',
  set_date_range_to_sync: 'Set Date Range to Sync',
  all_the_data_is_successfully_synced_close_this_window_to_see_updated_data_tables:
    'All the Data is successfuly synced. Close this window to see updated data tables.',
  sync_successfull: 'Sync Successfull',
  you_can_see_your_sync_progress_in_the_activity_tab:
    'You can see your sync progress in the activity tab.',
  to: 'To',
  lists: 'Lists',
  status: 'Status',
  create: 'Create',
  created: 'Created',
  leads: 'Leads',
  customer: 'Customers',
  new_customer: 'New Customers',
  best_customer: 'Best Customers',
  big_spendors: 'Big Spendors',
  abandon_cart: 'Abandon Cart',
  low_value_customer: 'Low Value Customers',
  valid_till: 'Valid till',
  about_to_sleep: 'About to Sleep',
  lists_line_1:
    'Lists are HubSpot lists that update automatically, enrolling the contacts who meet the membership criteria and removing those who no longer meet it.',
  lists_line_2:
    'It is designed to get a qualified lead to make the first purchase.',
  workflow: 'Workflow',
  workflow_line_1:
    'Workflows are HubSpot lists that update automatically, enrolling the contacts who meet the membership criteria and removing those who no longer meet it.',
  workflow_details: 'Workflow Details',
  workflow_line_2: 'BigCommerce: MQL to Customer lifecycle stage Conversion',
  groups_and_properties: 'Groups & Properties',
  groups_properties_line_1:
    'Groups & properties are used to store data on certain objects in HubSpot, such as contacts, companies, deals, and tickets. You can have upto 1,000 properties per object,including the default HubSpot properties.',
  hubspot_data_logs: 'HubSpot Data Logs',
  apply: 'Apply',
  include_resolved_error: 'Include Resolved Error:',
  error_type: 'Error Type:',
  object_type: 'Object Type',
  limit: 'limit',
  please_enter: 'Please enter between 1-200',
  note: 'Note: HubSpot logs could get used to filtering the HubSpot errors/warning occurred during data syncing.',
  logs_detail: 'Logs Detail',
  month: 'Month',
  saving_64_a_year: 'Saving $64 a year',
  yes_take_me_to_hubspot: 'Yes, take me to HubSpot',
  cancel: 'Cancel',
  this_will_redirect_you_to_hubspot:
    'This will redirect you to hubspot page where you can create a new account.',
  saved_mapping_data: 'Saved Mapping Data',
  create_groups_properties: 'Create Groups & Properties',
  something_went_wrong: 'Something went wrong, please try again later',
  lists_creation_in_progress:
    'Lists creation is in progress. This should only take a few moments. Thanks for your patience!',
  workflows_creation_in_progress:
    'Workflows creation is in progress. This should only take a few moments. Thanks for your patience!',
  bigcommerce_order_status_deal_stages:
    'Bigcommerce Order Status & Deal stages',
  data_type: 'Data type',
  orders: 'Orders',
  customers: 'Customers',
  first_name: 'First Name',
  last_name: 'Last Name',
  company: 'Company',
  configurations: 'Configurations',
  automatic_sync: 'Automatic Sync',
  roi_calculation_setting: 'ROI Calculation Setting',
  calculate_roi_for_the_selected_status:
    'Calculate ROI for the selected status',
  rfm_settings_manage_roi_tracking: 'RFM Settings (Manage ROI tracking)',
  enable_disable: 'Enable/Disable',
  abondantcart_sync: 'Abandonedcart sync',
  contact_sync: 'Contact sync',
  rating_for_rfm_segmentation: '(Rating for RFM Segmentation)',
  days_since_last_order: '(Days Since last Order)',
  total_orders_placed: '(Total Orders Placed)',
  total_money_spent: '(Total Money Spent)',
  score: 'Score',
  recency: 'Recency',
  frequency: 'Frequency',
  monetary: 'Monetary',
  less_than: 'Less than',
  more_than: 'More than',
  from: 'From',
  value_must_be_positive_integer: 'Value must be positive integer',
  search_customer: 'Search customer...',
  search_product: 'Search product...',
  image: 'Image',
  id: 'ID',
  title: 'Title',
  inventory: 'Inventory',
  sku: 'SKU',
  weight: 'Weight',
  price: 'Price',
  order_id: 'Order ID',
  customer_id: 'Customer ID',
  date_created: 'Date Created',
  currency: 'Currency',
  amount: 'Amount',
  total_tax: 'Total Tax',
  search_orders: 'Search Orders',
  import: 'Import',
  export: 'Export',
  view_in_hubspot: 'View in Hubspot',
  select_atleast_one_product_to_export: 'Select atleast one product to export',
  select_atleast_one_order_to_export: 'Select atleast one order to export',
  select_atleast_one_customer_to_export:
    'Select atleast one customer to export',
  no_activities_found: 'No Activities Found',
  sync_in_progress: 'Sync in Progress',
  help_and_support: 'Help and Support',
  documentation: 'Documentation',
  contact_us: 'Contact us',
  sorry_page_not_found: 'Sorry! Page not found',
  no_page_found: 'No Page found',
  go_to_home: 'Go to home',
  oops_error_occured: 'Oops, error occurred',
  external_object_id: 'External Object ID',
  operation: 'Operation',
  action: 'Action',
  created_at: 'Created At',
  activities: 'Activities',
  view_all: 'View All',
  no: 'No',
  has_been_found: 'has been found',
  hub_properties: 'Hub Properties',
  log_details: 'Log Details',
  lists_details: 'Lists Details',
  deal_contact: 'Deal Contact',
  line_item: 'Line Item',
  line_item_deal: 'Line Item Deal',
  sync_unsuccessfull: 'Sync Unsuccessfull',
  unable_to_sync_data_please_try_again_to_sync_successfully:
    'Unable to sync all the data. Please try again to sync successfully.',
  no_activity_found: 'No Activity Found',
  recent_data_updates: 'Recent Data Updates',
  enable_date_range: 'Enable Date Range',
  view_details: 'View Details',
  clear_all: 'Clear all',
  internal_object_id: 'Internal Object ID',
  sync_initiated_pleace_check_activities:
    'Sync initiated, you can check the progress in activities section',
  all: 'All',
  contact: 'Contact',
  deal: 'Deal',
  details: 'Details',
  view: 'View',
  portal_id: 'Portal ID',
  import_data_by_type_and_date_range: 'Import data by type and date range',
  export_data_by_type_and_date_range: 'Export data by type and date range',
  logs_will_automatically_removed_after_seven_days:
    'Logs will automatically removes after seven days',
  hi_welcome: 'Hi Welcome',
  login: 'Login',
  enter_your_details_below: 'Enter your details below',
  username: 'Username',
  password: 'Password',
  your_active_plan: 'Your Active Plan',
  cancel_plan: 'Cancel Plan',
  free: 'Free',
  plans_and_pricing: 'Plans & Pricing',
  choose_plan: 'Choose Plan',
  contact_now: 'Contact Now',
  join_our_pro_features_for_unlimited_without_any_contract_and_cancel_anytime:
    'Join our pro features for unlimited without any contract and cancel any time',
  billing_details: 'Billing Details',
  full_name: 'Full Name',
  email_address: 'Email Address',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  country: 'Country',
  select_country: 'Select Country',
  payment_info: 'Payment Info',
  order_summary: 'Order Summary',
  sub_total: 'Sub Total',
  discount: 'Discount',
  plan_details: 'Plan Details',
  username_is_required: 'Username is required',
  email_is_required: 'Email is required',
  email_must_be_valid: 'Email must be a valid email address',
  password_is_required: 'Password is required',
  select_unsynced: 'Select Unsynced',
  invoice_history: 'Invoice History',
  credit: 'Credit',
  total_credit: 'Total Credit',
  upgrade_plan: 'Upgrade Plan',
  available_credit: 'Available Credit',
  done: 'Done',
  no_records_found: 'No Records Found',
  saving_a_year: 'Saving ${{value}} a year',
  total: 'Total',
  name_is_required: 'Name is required',
  phone_number_not_valid: 'Phone number not valid',
  email_must_be_a_valid_email_address: 'Email must be a valid email address',
  address_is_required: 'Address is required',
  city_is_required: 'City is required',
  state_is_required: 'State is required',
  zip_is_required: 'Zip is required',
  country_is_required: 'Country is required',
  complete_payment: 'Complete payment',
  plan: 'Plan',
  payment_successful_redirect_to_plans:
    'Payment successful redirectin to plans',
  monthly: 'Monthly',
  yearly: 'Yearly',
  prototypes_3: '3 prototypes',
  boards_3: '3 boards',
  up_to_5_team_members: 'Up to 5 team members',
  advanced_security: 'Advanced security',
  permissions_workflows: 'Permissions & workflows',
  recommended: 'Recommended',
  sync_unlimited_customers: 'Sync unlimited customers',
  sync_unlimited_products: 'Sync unlimited products',
  sync_100_orders_month: 'Sync up to 100 orders/month',
  sync_abondoned_carts: 'Sync abandoned carts',
  order_sync_limit_100_month: 'Orders Sync Limited to 100/month',
  contact_custom_properties_90: '90 Contact custom properties',
  deal_custom_properties_5: '5 Deal custom properties',
  product_custom_properties_3: '3 product custom properties',
  smart_lists_3: '3 Smart lists',
  rfm_segmentation: 'RFM Segmentation',
  technical_support_for_onboarding_process:
    'Techincal support for Onboarding Process',
  sync_500_orders_month: 'Sync up to 500 orders/month',
  unlimited_historical_data_sync_orders_limited_to_500_month:
    'Unlimited historical data sync (Orders Limited to 500/month)',
  ready_to_use_workflows_15: '15 Ready to use Workflows',
  technical_support: 'Technical support',
  sync_unlimited_orders: 'Sync unlimited orders',
  unlimited_historical_data_sync: 'Unlimited historical data sync',
  prioritized_technical_support: 'Prioritized Technical support',
  portal_reconnect: 'Portal reconnect',
  user: 'User',
  issue_payment_failed: 'Issue payment failed',
  enabled: 'Enabled',
  disabled: 'Disabled',
  add_hubspot_script: 'Add HubSpot Script',
  refresh: 'Refresh',
};

export default en;

import {
  APP_CODE,
  APP_TAG,
  SOURCE_NAME,
  TARGET_NAME,
} from 'api/ApiConstants/BigcommerceHubspotApiConstants';
import jwtDecode from 'jwt-decode';
import axios from './axios';
const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.appCode = APP_CODE;
    axios.defaults.headers.common.appTag = APP_TAG;
    axios.defaults.headers.common['Ced-Source-Name'] = SOURCE_NAME;
    axios.defaults.headers.common['Ced-Target-Name'] = TARGET_NAME;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.appCode;
    delete axios.defaults.headers.common.appTag;
    delete axios.defaults.headers.common['Ced-Source-Name'];
    delete axios.defaults.headers.common['Ced-Target-Name'];
    delete axios.defaults.headers.common['Ced-Source-Id'];
    delete axios.defaults.headers.common['Ced-Target-Id'];
  }
};

export { isValidToken, setSession };
